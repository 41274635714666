export function absolutize(url: string) {
  return new URL(url, window.location.href).href;
}

export function navigateOneLevelUp(): void {
  const pathSegments: string[] = window.location.pathname.split('/');
  // first segment ist empty
  // second segment is DE or EN
  if (pathSegments.length > 3) {
    pathSegments.pop();
    let url = `${window.location.origin}${pathSegments.join('/')}`;
    if(!url.endsWith('/') && !url.endsWith('.html')) {
      url = url + '/';
    }

    history.pushState(null, '', url);
  }
}