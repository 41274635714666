import { FilterMenuComponent } from '../components/filter-menu.compnent';
import { Filterable } from '../models/filterable.interface';
import { SlotId } from '../models/router.types';

import { AbstractContentController } from '../components/abstract-content.controller';
import { navigateOneLevelUp } from '../utils';

export class PortfolioController extends AbstractContentController implements Filterable {
  private projects: HTMLAnchorElement[];
  private filterMenu: FilterMenuComponent;
  private overlay: HTMLDivElement;
  private overlayCloseBtn: HTMLButtonElement;
  private activeSlideIndex: number = 0;
  private slideImages: HTMLImageElement[] = [];
  private infoDetailsBtn: HTMLButtonElement;
  private infoDetailsPanel: HTMLDivElement;

  public override attach(doc: Document, slot: SlotId): void {
    super.attach(doc, slot);
    this.projects = [...this.pageContent.querySelectorAll<HTMLAnchorElement>('a.project')];
    this.overlay = this.pageContent.querySelector('#projectDetails')!
    this.overlayCloseBtn = this.overlay.querySelector<HTMLButtonElement>('.close-button')!;

    this.filterMenu = this.initFilter();
    this.filterMenu.addObserver(this);

    this.initDetailsView();
    this.initSlideShow();
    this.addEventListeners();
  }

  public override eject(): void {
    this.ejectChildContent();
    this.filterMenu.destroy();
    this.removeEventListeners();
  }

  public containsRouteWithin(url: string): boolean {
    return this.projects.some((project) => project.href === url);
  }

  public override attachChild(doc: Document) {
    this.ejectChildContent();
    const overlayContentHolder: HTMLDivElement = this.overlay.querySelector<HTMLDivElement>('.project-details-content')!;
    overlayContentHolder.replaceWith(doc.getElementById('projectDetails')!.querySelector<HTMLDivElement>('.project-details-content')!);

    this.initDetailsView();
    this.initSlideShow();
    this.openOverlay();
  }

  public updateFilters(filters: string[]): void {
    for(const project of this.projects) {
      const categories = project.dataset.categories!.split(',');
      const filterApplies = categories.some((category) => filters.includes(category)) || !filters.length;

      project.classList.toggle('hidden', !filterApplies);
    }
  }

  private ejectChildContent(): void {
    this.overlay.querySelectorAll<HTMLButtonElement>('.slide-control').forEach((button) => button.removeEventListener('click', this.handleSlideShowControl))
    this.infoDetailsBtn?.removeEventListener('click', this.toggleInfoDetails);
  }

  private initSlideShow(): void {
    this.activeSlideIndex = 0;
    this.slideImages = [...this.overlay.querySelectorAll<HTMLImageElement>('.slide-show > img')];
    this.setActiveSlide();
    this.overlay.querySelectorAll<HTMLButtonElement>('.slide-control').forEach((button) => button.addEventListener('click', this.handleSlideShowControl));
  }

  private initDetailsView(): void {
    this.infoDetailsBtn = this.overlay.querySelector<HTMLButtonElement>('.button-details')!;
    this.infoDetailsPanel = this.overlay.querySelector<HTMLDivElement>('.project-info-details')!
    this.infoDetailsBtn?.addEventListener('click', this.toggleInfoDetails);
  }

  private addEventListeners(): void {
    this.overlayCloseBtn.addEventListener('click', this.handleCloseOverlay);
  }

  private removeEventListeners(): void {
    this.overlayCloseBtn.removeEventListener('click', this.handleCloseOverlay);
  }

  private initFilter(): FilterMenuComponent {
    return new FilterMenuComponent(this.pageContent.querySelector('.filter-menu')!, this.getSetOfFilters());
  }

  private getSetOfFilters(): string[] {
    const filters: string[] = this.projects.reduce((acc: string[], project) => {
      return [...acc, ...project.dataset.categories!.split(',')];
    }, []);
    return [...new Set(filters)].sort();
  }

  private handleCloseOverlay = (event: MouseEvent) => {
    event.stopImmediatePropagation();
    this.overlay.classList.remove('open');
    navigateOneLevelUp();
  }

  private toggleInfoDetails = (event: MouseEvent): void => {
    this.infoDetailsPanel.classList.toggle('open');
    this.infoDetailsBtn.classList.toggle('active');
  }

  private openOverlay(): void {
    this.overlay.classList.add('open');
  }

  private handleSlideShowControl = (event: MouseEvent) => {
    const button: HTMLButtonElement = event.target as HTMLButtonElement;
    if(button.closest('.prev-button')) {
      this.activeSlideIndex = Math.max((this.activeSlideIndex - 1), 0);
    } else if(button.closest('.next-button')) {
      this.activeSlideIndex = Math.min((this.slideImages.length - 1), (this.activeSlideIndex + 1));
    }

    this.setActiveSlide();
  }

  private setActiveSlide(): void {
    this.slideImages[this.activeSlideIndex]?.scrollIntoView();
    this.slideImages.forEach((img, index) => img.classList.toggle('active', index === this.activeSlideIndex));
  }
}