import { mapping } from './controller';
import { AbstractContentController } from './components/abstract-content.controller';
import { ContentController } from './models/content.types';
import { RouteConfig, RouteListener, SlotId } from './models/router.types';
import { FlipState } from './flip-state';
import { absolutize } from './utils'
import { DocumentCache } from './cache';

export class Router {
  private routeConfigs: Map<string, RouteConfig> = new Map();
  private cache: DocumentCache = new DocumentCache();
  private activeRoute: string | undefined = undefined;
  private activeController: ContentController | null;
  private flipState: FlipState;
  private subscribers: RouteListener[] = [];

  public init(routerConfig: RouteConfig[], doc: Document) {
    this.flipState = new FlipState(doc);
    this.parseConfig(routerConfig);
    this.addListeners();
    this.resolveRoute(doc);
  }

  public subscribe(listener: RouteListener | RouteListener[]) {
    if(Array.isArray(listener)) {
      this.subscribers.push(...listener);
    } else {
      this.subscribers.push(listener);
    }
  }

  public async navigateTo(route: string): Promise<void> {
    this.updateHistoryState(route);
    await this.resolveRoute();
  }

  private async resolveRoute(initialDoc?: Document) {
    const url = this.getRoute();
    const routeConfig = this.routeConfigs.get(url);
    if (!routeConfig) {
      // TODO: Handle more gracefully
      // Maybe just redirect to requested url?
      throw (new Error('RoutingError::No RouteConfig found'));
    }

    const doc = initialDoc ?? await this.cache.getDocument(url);
    if (this.activeController?.containsRouteWithin(url)) {
      this.activeController.attachChild(doc, url);
    } else {
      const controller = this.getController(routeConfig);
      this.activeController?.eject();
      controller.attach(doc, routeConfig.slot);
      this.activeController = controller;
    }

    const activeSlotId = this.getSlotId(url);
    this.activeRoute = url;
    this.flipState.flipTo(activeSlotId);
    if(activeSlotId) {
      this.subscribers.forEach((listener) => listener.update(url, activeSlotId, doc));
    }
  }

  private parseConfig(routerConfig: RouteConfig[]) {
    for (const route of routerConfig) {
      this.routeConfigs.set(absolutize(route.path), route);
    }
  }

  private updateHistoryState(route: string) {
    history.pushState(null, '', route);
  }

  private addListeners(): void {
    window.addEventListener('popstate', this.handlePopState);
    document.addEventListener('click', this.handleGlobalClick);
  }

  private handlePopState = (event: PopStateEvent) => {
    this.resolveRoute();
  }

  private handleGlobalClick = (event: MouseEvent) => {
    const url = this.getLinkUrl(event.target);
    if(url && this.routeConfigs.has(url)) {
      event.preventDefault();
      event.stopPropagation();
      this.navigateTo(url);
    }
  }

  private getLinkUrl(target: EventTarget | null): string | null {
    const link:HTMLAnchorElement | null = (target as HTMLElement).closest('[href]');
    if (link && link.href) {
      return absolutize(link.href);
    }

    if(this.isSwitchButton(target)) {
      return absolutize(target.parentElement!.dataset.relatedpage!);
    }

    return null;
  }

  private isSwitchButton(target: EventTarget | null): target is HTMLButtonElement {
    return target instanceof HTMLButtonElement && target.classList.contains('switch-button');
  }

  private getController(routeConfig: RouteConfig): ContentController {
    const controllerClass = mapping[routeConfig.template] ?? AbstractContentController;
    console.log('Instantiating Controller for Template: ', routeConfig.template, routeConfig, controllerClass);
    return new controllerClass();
  }

  private getSlotId(url: string): SlotId | undefined {
    return this.routeConfigs.get(url)?.slot;
  }

  private getRoute(): string {
    return `${window.location.origin}${window.location.pathname}`;
  }
}
