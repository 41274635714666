import { PageTheme, SlotId } from '../models/router.types';
import { ContentController } from '../models/content.types';

export class AbstractContentController implements ContentController {
  protected pageContent: HTMLElement;

  public attach(doc: Document, slot: SlotId): void {
    const slotElement = doc.getElementById(slot)!
    this.setPageTheme(slot, slotElement!.dataset['theme'] as PageTheme);
    this.pageContent = slotElement.querySelector<HTMLElement>('.page-content')!
    this.injectPage(slot);
    this.pageContent.parentElement!.scrollTo({top: 0, left: 0, behavior: 'instant'});
  }

  public attachChild(doc: Document, url: string): void {

  }

  public eject(): void {

  }

  public containsRouteWithin(url: string): boolean {
    return false;
  }

  public setPageTheme(slot: SlotId, theme?: PageTheme): void {
    const currentTheme = document.getElementById(slot)!.dataset['theme'];
    if(currentTheme !== theme) {
      document.getElementById(slot)!.dataset['theme'] = theme ?? '';
    }
  }

  private injectPage(slot: SlotId) {
    const slotPageContent = document.getElementById(slot)!.querySelector('.page-content');
    if(slotPageContent !== this.pageContent) {
      slotPageContent?.replaceWith(this.pageContent);
    }
  }
}
