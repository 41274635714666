import { initBackgroundPointerUpdate } from "./animation/background";
// import { initCustomCursor } from './animation/cursor';

import { FooterComponent } from './components/footer.component';
import { MetaInfoComponent } from './components/meta-info.component';
import { PageHeaderComponent } from './components/page-header.component';
import { Router } from "./router";

async function init() {
  initBackgroundPointerUpdate();
  // initCustomCursor();

  const [, firstSegment] = location.pathname.split("/");
  let url = "/";
  if (["de", "en"].includes(firstSegment)) {
    url += firstSegment + "/";
  }

  const routerConfig = await (await fetch(url + "routes.json")).json();
  const router = new Router();
  const mainNavigation: PageHeaderComponent[] = [...document.querySelectorAll<HTMLElement>('.page-header')].map((header) => new PageHeaderComponent(header));
  const footerNavigation: FooterComponent[] = [...document.querySelectorAll<HTMLElement>('.page-footer')].map((footer) => new FooterComponent(footer));
  const metaInfo: MetaInfoComponent = new MetaInfoComponent();
  router.subscribe([...mainNavigation, ...footerNavigation, metaInfo]);
  router.init(routerConfig, document);
}

init().catch(console.log);