import { RouteListener, SlotId } from '../models/router.types';

export class PageHeaderComponent implements RouteListener {
  private header: HTMLElement;
  private mainMenu: HTMLElement;
  private navLinks: NodeListOf<HTMLAnchorElement>;
  private switchButton: HTMLElement;
  private activePageName: HTMLElement;
  private pageSide: SlotId;

  constructor(header: HTMLElement) {
    this.header = header;
    this.pageSide = header.closest('.page')!.id as SlotId;
    this.mainMenu = header.querySelector('.main-menu')!;
    this.navLinks = this.mainMenu.querySelectorAll('a');
    this.switchButton = header.querySelector('.switch')!;
    this.activePageName = header.querySelector('.active-page-name')!;
    this.addEventListeners();
  }

  public update(url: string, activeSide: SlotId, doc: Document): void {
    if(activeSide !== this.pageSide) {
      return;
    }

    let activePageNameFound: boolean = false;
    this.navLinks.forEach((anchor) => {
      const link: HTMLLIElement = anchor.parentElement as HTMLLIElement;
      link.classList.toggle('active', anchor.href === url);
// TODO: find most likely parent and use that name
      if(anchor.href === url) {
        this.setActivePageName(anchor);
        activePageNameFound = true;
      }
    });

    this.switchButton.classList.toggle('checked', activeSide === 'page--back');
    if(!activePageNameFound) {
      this.resetActivePageName();
    }

    this.updateHeaderMode(doc, activeSide);
  }

  private addEventListeners(): void {
    this.header.closest('.page')!.addEventListener('scroll', this.handleScroll);
  }

  private handleScroll = (event: Event) => {
    const target = event.target as HTMLElement;
    this.header.classList.toggle('scrolled', target.scrollTop > 0)
  }

  private setActivePageName(anchor: HTMLAnchorElement): void {
    let pageName = anchor.dataset.menuName;
    if(!pageName) {
      const topMost:HTMLAnchorElement = anchor.closest('ul.top-level')?.firstChild?.firstChild as HTMLAnchorElement;
      pageName = topMost.textContent ?? '';
    }

    this.activePageName.innerText = pageName ?? '';
  }

  private resetActivePageName(): void {
    this.activePageName.innerText = '';
  }

  private updateHeaderMode(doc: Document, activeSide: SlotId): void {
    this.header.dataset['headerMode'] = doc.getElementById(activeSide)?.querySelector<HTMLElement>('.page-header')?.dataset['headerMode'];
  }
}