import { PageSide, SlotId } from '../models/router.types';
import { AbstractContentController } from '../components/abstract-content.controller';

export class AccordionSectionController extends AbstractContentController {
  private accordionSection: HTMLElement[] = [];
  private slot: SlotId;
  private activeSection: HTMLElement | null = null;

  public override attach(doc: Document, slot: SlotId): void {
    super.attach(doc, slot);
    this.slot = slot;
    this.accordionSection = [...this.pageContent.querySelectorAll<HTMLElement>('.accordion-segment')];
    this.accordionSection.forEach((section) => section.querySelector<HTMLElement>('.accordion-segment-url')!.addEventListener('click', this.closeSection));
    this.activeSection = this.pageContent.querySelector<HTMLElement>('.accordion-segment.open') ?? null;
  }

  public override attachChild(doc: Document, url: string): void {
    const activeSection = this.getActiveSection(url);
    this.injectSectionContent(doc, url, activeSection);
    this.activate(activeSection);
  }

  public override eject(): void {
    this.accordionSection.forEach((section) => section.querySelector<HTMLElement>('.accordion-segment-url')!.removeEventListener('click', this.closeSection));
  }

  public override containsRouteWithin(url: string): boolean {
    return !!this.getSectionLink(url);
  }

  private injectSectionContent(doc: Document, url: string, activeSection: HTMLElement | null) {
    if (!activeSection) {
      return;
    }

    const slotPageContent = doc.getElementById(this.slot)!.querySelector<HTMLElement>('.page-content');
    const sectionContent = this.getActiveSection(url, slotPageContent ?? undefined)?.querySelector('.accordion-segment-content') ?? null;
    if(!sectionContent) {
      return;
    }

    activeSection.querySelector('.accordion-segment-content')?.replaceWith(sectionContent);
  }

  private getSectionLink(url:string, target?: HTMLElement): HTMLAnchorElement | null {
    target = target ?? this.pageContent;
    const sectionLinks = [...target.querySelectorAll<HTMLAnchorElement>('a.accordion-segment-url')];

    return sectionLinks.find((link) => link.href === url) ?? null;
  }

  private getActiveSection(url: string, target?:HTMLElement): HTMLElement | null {
    const sectionLink = this.getSectionLink(url, target);
    if (!sectionLink) {
      return null;
    }

    return sectionLink.closest<HTMLElement>('.accordion-segment') ?? null;
  }

  private activate(activeSection: HTMLElement | null) {
    this.activeSection = activeSection;
    this.accordionSection.forEach((section) => section.classList.toggle('open', section === activeSection));
  }

  private closeSection = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const section = target.closest('.accordion-segment');
    if(section !== this.activeSection) {
      return;
    }

    this.activeSection?.classList.toggle('open');
  }
}
