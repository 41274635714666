import { RouteListener, SlotId } from '../models/router.types';

export class FooterComponent implements RouteListener {
  private navLinks: NodeListOf<HTMLAnchorElement>;
  private footer: HTMLElement;
  private mySide: SlotId;

  constructor(footer: HTMLElement) {
    this.footer = footer;
    this.mySide = footer.closest('.page')!.id as SlotId;
    this.setNavLinks();
  }

  public update(url: string, activeSide: SlotId, doc: Document): void {
    this.updateLanguageNav(activeSide, doc);
    this.resetActiveLink(url);
  }

  private setNavLinks() {
    this.navLinks = this.footer.querySelectorAll('.footer-menu a')!;
  }

  private resetActiveLink(url: string) {
    this.navLinks.forEach((anchor) => {
      const link: HTMLLIElement = anchor.parentElement as HTMLLIElement;
      link.classList.toggle('active', anchor.href === url);
    });
  }

  private updateLanguageNav(activeSide: SlotId, doc: Document): void {
    if(this.mySide !== activeSide) {
      return;
    }

    const newLanguageMenu = doc.getElementById(activeSide)!.querySelector('.page-footer > .language-menu')!;
    this.footer.querySelector('.language-menu')?.replaceWith(newLanguageMenu);
  }
}