import { SlotId } from '../models/router.types';
import { AbstractContentController } from '../components/abstract-content.controller';

export class TeamController extends AbstractContentController {
  private memberLinks: HTMLAnchorElement[];
  private overlay: HTMLDivElement;

  public override attach(doc: Document, slot: SlotId): void {
    super.attach(doc, slot);
    this.memberLinks = [...this.pageContent.querySelectorAll<HTMLAnchorElement>('.team-member--link')];
    this.overlay = this.pageContent.querySelector('#teamMember')! as HTMLDivElement;
    this.addEventListeners();
  }

  public override eject() {
    this.removeEventListeners();
  }

  public containsRouteWithin(url: string): boolean {
    return this.memberLinks.some((link) => link.href === url);
  }

  public override attachChild(doc: Document, url: string) {
    console.log('TeamController:: attach child', url, doc);
    const overlayContentHolder: HTMLDivElement = this.overlay.querySelector<HTMLDivElement>('.team-member-content')!;
    overlayContentHolder.replaceWith(doc.getElementById('teamMember')!.querySelector<HTMLDivElement>('.team-member-content')!);

    this.openOverlay();
  }

  private addEventListeners() {
    this.overlay.addEventListener('click', this.handleCloseOverlay);
    this.memberLinks.forEach((link) => link.addEventListener('click' , this.handleMemberClicked));
  }

  private removeEventListeners() {
    this.overlay.removeEventListener('click', this.handleCloseOverlay);
    this.memberLinks.forEach((link) => link.removeEventListener('click', this.handleMemberClicked));
  }

  private openOverlay(): void {
    this.overlay.classList.add('open');
  }

  private handleCloseOverlay = (event: MouseEvent) => {
    event.stopImmediatePropagation();
    this.overlay.classList.remove('open');
  }

  private handleMemberClicked = (event: MouseEvent) => {
    const target: HTMLAnchorElement = (event.target as HTMLElement).closest('[href]') as HTMLAnchorElement;
    console.log('handle member clicked', target);
    if(target.classList.contains('active')) {
      this.openOverlay();
    } else {
      this.memberLinks.forEach((link) => link.classList.toggle('active', target === link));
    }
  }
}
